import styled, { createGlobalStyle } from 'styled-components'
import OthersListBg from '../images/questionnaire/questionnaire-bg.png'

export const QuestionPageStyle = createGlobalStyle`
  html {
    background-image: ${`url(${OthersListBg})`};
    min-height: 100vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
`

export const QuestionPage = styled.div`
  .submit-btn {
    background-color: #13cb75;
    width: 120px;
    height: 40px;
    box-shadow: none;
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    border-radius: 6px;
  }
`

export const QuestionContain = styled.div`
  width: 775px;
  background: #fff;
  margin: 100px auto 100px;
  padding: 50px 37px;
`
export const Title1 = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`
export const Title2 = styled.p`
  font-size: 18px;
  text-align: center;
  color: #666666;
`

export const Title3 = styled.div`
  font-size: 20px;
  line-height: 50px;
`

export const InputQuestion = styled.div`
  &:focus-visible {
    outline: none;
  }
  input {
    width: calc(100% - 30px);
    height: 40px;
    border: none;
    background: #f8f9fa;
    padding-left: 10px;
    border-radius: 5px;
    margin-left: 30px;
    &:focus-visible {
      outline: none;
    }
  }
`

export const QuestionItem = styled.div`
  margin-top: 30px;
`
export const OptionItem = styled.div`
  font-size: 16px;
  margin-left: 30px;
  color: #333333;
  line-height: 30px;
  display: flex;
  align-items: center;
  input[type='radio'] {
    width: 15px;
    height: 15px;
  }
  .checkbox-item {
    width: 18px;
    height: 18px;
  }
`

export const QuestionTips = styled.div`
  text-align: center;
  margin: 100px;
  img {
    width: 40px;
  }
  p {
    color: #666666;
  }
`

export const QuestionTipsStyle = createGlobalStyle`
  html {
    background-color: #fff;
  }
`

export const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin: 5px 30px;
`
