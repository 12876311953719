import React, { useState, useEffect } from 'react'
import { API, Axios } from '@renderbus/common/utils'
import { Button } from '@renderbus/common/components'
import { QuestionPageStyle, QuestionPage } from './questionnarie.atom'
import { useLocation } from '@reach/router'
import { isTest } from '@renderbus/common/service'
import {
  QuestionContain,
  Title1,
  Title2,
  Title3,
  InputQuestion,
  QuestionItem,
  OptionItem,
  QuestionTips,
  QuestionTipsStyle,
  ErrorText,
} from './questionnarie.atom'
import SuccessIcon from '../images/questionnaire/icon01.svg'
import TipsIcon from '../images/questionnaire/icon02.svg'
import WarningIcon from '../images/questionnaire/icon03.svg'

function Questionnaire(props) {
  const [answers, setAnswers] = useState({})
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState() // 0访问问卷，1提交成功，2暂停收集，3次数已达上限
  const instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)
  const location = useLocation()
  const [questionList, setQuestionList] = useState([])
  const [title, setTitle] = useState('')
  const [introduction, setIntroduction] = useState('')
  const [answerList, setAnswerList] = useState([])
  const [userId, setUserId] = useState()
  const [projectId, setProjectId] = useState()
  const [disableClick, setDisableClick] = useState(true)
  const [showError, setShowError] = useState(false)
  function handleAnswerChange(id, answer, type) {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: answer,
    }))
    if (type === 2) {
      setShowError(false)
      if (answer.trim().length > 500) {
        setShowError(true)
      }
    }
  }
  function handleSubmit() {
    setLoading(true)
    instance
      .post(API.event.submitQuestionnaire, {
        userId: userId,
        projectId: projectId,
        answerList: answerList,
      })
      .then((res) => {
        setLoading(false)
        if (res.code === 200) {
          setStatus(1)
        } else if (res.code === 729) {
          setStatus(2)
        } else if (res.code === 728) {
          setStatus(3)
        }
      })
  }

  useEffect(() => {
    let params = new URLSearchParams(location.search)
    const userid = params.get('userId') ? Number(params.get('userId')) : ''
    const projectid = params.get('userId') ? Number(params.get('projectId')) : ''
    if (!userid || !projectid) {
      return
    } else {
      setUserId(userid)
      setProjectId(projectid)
    }
    instance
      .post(API.event.queryQuestionnaire, {
        projectId: projectid,
        userId: userid,
      })
      .then((res) => {
        if (res.code === 200 && res.data) {
          setStatus(0)
          setQuestionList(res.data.questionList)
          setTitle(res.data.questionnaire)
          setIntroduction(res.data.introduction)
        } else if (res.code === 729) {
          setStatus(2)
        } else if (res.code === 728) {
          setStatus(3)
        }
      })
  }, [location])

  useEffect(() => {
    if (questionList.length > 0) {
      const answerList = questionList.map((item, index) => {
        return {
          id: item.id,
          answer: answers[index]?.toString(),
        }
      })
      const fillBlankIdList = questionList
        .filter((item) => item.questionType === 2)
        .map((i) => i.id)
      if (
        answerList.some(
          (item) =>
            !item.answer ||
            (fillBlankIdList.includes(item.id) &&
              (item.answer.trim().length > 500 || item.answer.trim().length === 0)),
        )
      ) {
        setDisableClick(true)
      } else {
        setAnswerList(answerList)
        setDisableClick(false)
      }
    }
  }, [questionList, answers])

  return (
    <QuestionPage>
      {status === 0 && (
        <QuestionContain>
          <Title1>{title}</Title1>
          <Title2>{introduction}</Title2>
          {questionList.map((question, index) => (
            <Question
              key={question.id}
              title={question.questionTitle}
              type={question.questionType}
              options={question.questionOptionList}
              index={index}
              answers={answers}
              setAnswers={setAnswers}
              handleAnswerChange={handleAnswerChange}
              handleSubmit={handleSubmit}
              showError={showError}
            />
          ))}
          <Button className='submit-btn' onClick={handleSubmit} disabled={loading || disableClick}>
            {loading ? '提交中' : '提交'}
          </Button>
          <QuestionPageStyle />
        </QuestionContain>
      )}
      {status === 1 && (
        <QuestionTips>
          <img src={SuccessIcon} alt='' />
          <h2>调查问卷</h2>
          <p>提交成功！感谢您的参与！</p>
          <QuestionTipsStyle />
        </QuestionTips>
      )}
      {status === 2 && (
        <QuestionTips>
          <img src={TipsIcon} alt='' />
          <h2>调查问卷</h2>
          <p>该问卷已暂停收集！</p>
          <QuestionTipsStyle />
        </QuestionTips>
      )}
      {status === 3 && (
        <QuestionTips>
          <img src={WarningIcon} alt='' />
          <h2>调查问卷</h2>
          <p>该问卷答题次数已上限！</p>
          <QuestionTipsStyle />
        </QuestionTips>
      )}
    </QuestionPage>
  )
}

function Question({ title, type, options, index, answers, handleAnswerChange, showError }) {
  return (
    <QuestionItem>
      <Title3>
        <span style={{ color: 'red' }}>*&nbsp;</span>
        <span>{index + 1}.&nbsp;</span>
        {title}
      </Title3>
      {type === 0 && (
        <>
          {options.map((option, optionIndex) => (
            <OptionItem key={optionIndex}>
              <input
                type='radio'
                name={`question-${index}`}
                id={`question-${index}-option-${optionIndex}`}
                value={option}
                checked={answers[index] === option}
                onChange={() => handleAnswerChange(index, option)}
              />
              <label htmlFor={`question-${index}-option-${optionIndex}`}>
                &nbsp;&nbsp;{String.fromCharCode(64 + parseInt(optionIndex + 1))}、{option}
              </label>
            </OptionItem>
          ))}
        </>
      )}
      {type === 1 && (
        <>
          {options.map((option, optionIndex) => (
            <OptionItem key={optionIndex}>
              <input
                className='checkbox-item'
                type='checkbox'
                name={`question-${index}-option-${optionIndex}`}
                id={`question-${index}-option-${optionIndex}`}
                value={option}
                checked={(answers[index] || []).includes(option)}
                onChange={(e) => {
                  handleAnswerChange(
                    index,
                    e.target.checked
                      ? [...(answers[index] || []), option]
                      : (answers[index] || []).filter((value) => value !== option),
                  )
                }}
              />
              <label htmlFor={`question-${index}-option-${optionIndex}`}>
                &nbsp;&nbsp;{String.fromCharCode(64 + parseInt(optionIndex + 1))}、{option}
              </label>
            </OptionItem>
          ))}
        </>
      )}
      {type === 2 && (
        <InputQuestion>
          <input
            type='text'
            name={`question-${index}`}
            id={`question-${index}`}
            value={answers[index] || ''}
            onChange={(e) => handleAnswerChange(index, e.target.value, type)}
            placeholder='请输入'
          />
          {showError && <ErrorText>不能输入超过500字符</ErrorText>}
        </InputQuestion>
      )}
    </QuestionItem>
  )
}

export default Questionnaire
